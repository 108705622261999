
import './TreeIcon.css';

const TreeIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23.651" height="27.781" viewBox="0 0 23.651 27.781" className='tree-icon'>
            <path id="Path_1" data-name="Path 1" d="M672.615,2342.979v26.781h22.651" transform="translate(-671.615 -2342.979)" fill="none" stroke="#707070" stroke-width="2"/>
        </svg>
    )
};

export default TreeIcon;
