import {
    Edit,
    SimpleForm,
    TextInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    BooleanInput,
    useRedirect,
} from 'react-admin';

const LessonEdit = (props) => {
    const redirect = useRedirect();

    const onSuccess = (data) => {
        redirect(`/set_contents/${encodeURIComponent(data.data.setContent)}/show`);
    }

    return (
        <Edit onSuccess={onSuccess} undoable={false} {...props}>
            <SimpleForm>
                <TextInput source={"name"} />
                <NumberInput source={"sortOrder"} step={1} />
                <BooleanInput source='activity' label="Activity" />
                <ReferenceInput label="Set Contents" source="setContent" reference="set_contents">
                    <SelectInput optionText="name" onChange={(value) => {
                        console.log (value);
                    }} />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    )
};


export default LessonEdit;
