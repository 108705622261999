import { useEffect, useState } from 'react';

import {
    useShowController,
    Labeled,
    ReferenceManyField,
    Title,
    useNotify,
    LinearProgress,
} from 'react-admin';
import {
    makeStyles,
    Button as MuiButton,
    Card,
    CardContent,
    Toolbar,
    Typography,
} from '@material-ui/core';
import {SaveAlt as SaveAltIcon, Share as ShareIcon} from '@material-ui/icons';
import { useDataProvider } from 'react-admin';
import { linkToRecord } from 'ra-core';

import generateCustomAdminPageStyles from '../shared/generateCustomAdminPageStyles';
import SlideGridList from '../slides/SlideGridList';
import BreadcrumbNavigator from '../shared/BreadcrumbNavigator';

const useStyles = makeStyles(generateCustomAdminPageStyles);

const LessonShow = (props) => {
  const [slides, setSlides] = useState([]);
  const [navigationPath, setNavigationPath] = useState([]);
  const [setContentName, setSetContentName] = useState(null);

  const { loaded, record } = useShowController(props);

  const classes = useStyles();

  const dataProvider = useDataProvider();
  const notify = useNotify();

  useEffect(() => {
    if (!record) return;

    const fetchSetContent = async () => {
      const { data: setContent } = await dataProvider.getOne('setContents', { id: record.setContent });
      setSetContentName(setContent.name);
      const { data: block } = await dataProvider.getOne('blocks', { id: setContent.block });
      const { data: set } = await dataProvider.getOne('sets', { id: setContent.set });
      const { data: strand } = await dataProvider.getOne('strands', { id: set.strand });

      let lessonName = record.name;
      if (record.activity) {
        const { data: name } = await dataProvider.getContainingLessonName({ id: record.originId });
        lessonName = `${record.name} (${name['hydra:member'][0]})`;
      }

      setNavigationPath([
        '...',
        {
          link: linkToRecord('#/set_contents', record.setContent, 'show'),
          label: `${strand.name} - ${set.name} - ${block.name}`,
        },
        lessonName,
      ]);
    };

    fetchSetContent();
  }, [dataProvider, loaded, record]);

  const generateLessonReelLink = async () => {
    const { data } = await dataProvider.getLessonPublicReel({ id: record.originId });

    const url = `${data.solsURL}/reel/public/${data.publicReel.id}`;
    await navigator.clipboard.writeText(url);

    notify('The URL is now on your clipboard!');
  }

  if (!record) {
    return null;
  }

  return (
    <>
      <Title title={`Lesson: ${record.name}`} />

      <Toolbar className={classes.toolbar}>
        <BreadcrumbNavigator navigationPath={navigationPath} />
          {!record.activity ?
              <MuiButton
                  size="small"
                  variant="text"
                  color="primary"
                  edge="end"
                  aria-label="view"
                  onClick={generateLessonReelLink}
                  startIcon={<ShareIcon/>}
              >
                  Shareable Link
              </MuiButton>
              :
              null
          }
        <MuiButton
          size="small"
          variant="text"
          color="primary"
          edge="end"
          aria-label="view"
          onClick={async () => {
              await dataProvider.update('lessons', {
                  id: `${record.id}/slides/set_order`,
                  data: slides.map((slide) => slide.originId)
              });
          }}
          startIcon={<SaveAltIcon />}
        >
          Save sort order
        </MuiButton>
      </Toolbar>

      <Card>
        <CardContent>
          <Labeled label="Set Content name">
            {setContentName ? <Typography variant="body2">{setContentName}</Typography> : <LinearProgress /> }
          </Labeled>

          {loaded && (
            <ReferenceManyField
              record={record}
              reference="slides"
              target="slides"
              label="Slides"
              basePath={props.basePath}
              filter={{lesson: record.id}}
              sort={{field: 'sortOrder', order: 'ASC'}}
            >
              <SlideGridList
                  record={record}
                  loaded={loaded}
                  lessonId={record.id}
                  slides={slides}
                  setSlides={setSlides}
              />
            </ReferenceManyField>
          )}
        </CardContent>
      </Card>
    </>
  )
};

export default LessonShow;
