import { decodeJwt } from 'jose';

let apiUrl = process.env.REACT_APP_API_ENTRYPOINT;
if (window._env_ && window._env_.API_URL) {
  apiUrl = window._env_.API_URL;
}

let tokenRefreshing = false;
const AuthProvider = {
  login: async ({username, password}) => {
    const request = new Request(`${apiUrl}/token/authenticate`, {
      method: "POST",
      body: JSON.stringify({email: username, password: password}),
      headers: new Headers({"Content-Type": "application/json"}),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({token, refresh_token}) => {
        localStorage.setItem("token", token);
        localStorage.setItem("refresh_token", refresh_token);
      });
  },
  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    return Promise.resolve();
  },
  checkAuth: async () => {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refresh_token");
    if (token && refreshToken) {
      const { exp } = decodeJwt(token);
      const currentTime = (new Date().getTime() / 1000) + 10;
      if (exp > currentTime) {
        if (!tokenRefreshing) {
          tokenRefreshing = true;
          const request = new Request(`${apiUrl}/token/refresh`, {
            method: 'POST',
            body: JSON.stringify({"refresh_token": refreshToken}),
            headers: new Headers({'Content-Type': 'application/json'}),
          });

          return fetch(request)
            .then(response => {
              if (response.status !== 200) {
                throw new Error(response.statusText);
              }
              return response.json();
            })
            .then(({token}) => {
              tokenRefreshing = false;
              localStorage.setItem("token", token);

              return Promise.resolve();
            });
        }
        return Promise.resolve();
      }
    }
    return Promise.reject();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => Promise.resolve(),
};

export default AuthProvider;
