import StrandIcon from '@material-ui/icons/SupervisedUserCircle';

import StrandList from './StrandList';
import StrandEdit from './StrandEdit';
import StrandShow from './StrandShow';

export default {
  icon: StrandIcon,
  list: StrandList,
  edit: StrandEdit,
  show: StrandShow,
};
