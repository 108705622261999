import { useCallback, useState } from 'react';

import { Button, useNotify, useDataProvider } from 'react-admin';

import {
  Button as MuiButton,
  Modal,
  Paper,
  Typography,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel'
import PublishIcon from '@material-ui/icons/Publish';

import ZipDropzone from './ZipDropzone';
import { subscribeToBulkUploadRequest } from './subscribeToBulkUploadRequest';

import './BulkUploadDialog.css';

const BulkUploadDialog = ({
  title,
  resource,
  successMessage = () => '',
  onSuccess = () => {},
  setContentId,
  categoryId,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [zipFile, setZipFile] = useState(null);

  const notify = useNotify();
  const dataProvider = useDataProvider();

  const onDropFile = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      setZipFile(null);

      return;
    }

    setZipFile(acceptedFiles[0]);
  }, []);

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    if (!zipFile || !(zipFile instanceof File)) {
      return;
    }

    try {
      const { data: bulkUploadRequest } = await dataProvider.create(resource, {
        file: zipFile,
        id: setContentId,
        categoryId,
      });

      const { data: record } = await dataProvider.getOne(resource, {
        id: bulkUploadRequest.theme ?? bulkUploadRequest.setContent
      });

      subscribeToBulkUploadRequest(bulkUploadRequest['@id'], (evt) => {
        const data = JSON.parse(evt.data);
        onSuccess(data);
      });

      notify(`${successMessage(record)}`, 'success');
    } catch (error) {
      if (error.data) {
        notify(error.data, 'warning');
      } else {
        notify(`${error.stack}`, 'warning');
      }
    }

    setOpenModal(false);
    setZipFile(null);
  };

  return (
    <>
      <Button
        onClick={() => setOpenModal(true)}
        label="Bulk upload"
      >
        <PublishIcon />
      </Button>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="bulk-upload-modal-title"
      >
        <div className="bulk-upload-modal">
          <Paper>
            <Typography id="bulk-upload-modal-title" variant="h5">
              {title}
            </Typography>

            <form onSubmit={handleSubmit}>
              <ZipDropzone zipFile={zipFile} onDropFile={onDropFile} />
              <section className='actions'>
                <div className='upload'>
                  <MuiButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      startIcon={<SaveIcon />}
                  >
                    Upload
                  </MuiButton>
                </div>
                <div className='cancel'>
                  <MuiButton
                      variant="contained"
                      type="submit"
                      startIcon={<CancelIcon />}
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenModal(false);
                      }}
                  >
                    Close
                  </MuiButton>
                </div>
              </section>
            </form>
          </Paper>
        </div>
      </Modal>
    </>
  );
};

export default BulkUploadDialog;
