
import { HydraAdmin, ResourceGuesser } from '@api-platform/admin';

import customRoutes from './routes';

import { Layout } from './layout';

import blocks from './blocks';
import categories from './categories';
import lessons from './lessons';
import setContents from './setContents';
import slides from './slides';
import strands from './strands';
import themes from './themes';

import authProvider from './AuthProvider';
import createDataProvider from './createDataProvider';

// noinspection JSUnresolvedVariable
const entrypoint = (window._env_ && window._env_.API_URL)
  ? window._env_.API_URL
  : process.env.REACT_APP_API_ENTRYPOINT;

export default () => (
  <HydraAdmin
    dataProvider={createDataProvider(entrypoint)}
    authProvider={authProvider}
    entrypoint={entrypoint}
    customRoutes={customRoutes}
    layout={Layout}
  >
    <ResourceGuesser name="categories" {...categories} />
    <ResourceGuesser name="themes" {...themes} />
    <ResourceGuesser name="blocks" {...blocks} />
    <ResourceGuesser name="strands" {...strands} />
    <ResourceGuesser name="slides" {...slides} />
    <ResourceGuesser name="sets" />
    <ResourceGuesser name="set_contents" {...setContents} />
    <ResourceGuesser name="lessons" {...lessons} />
  </HydraAdmin>
);
