import {FieldGuesser, ListGuesser} from "@api-platform/admin";
import {
  ChipField,
  ImageField,
  ReferenceField,
} from 'react-admin';

const SlideList = (props) => (
  <ListGuesser {...props}>
    <FieldGuesser source="name" />
    <FieldGuesser source={"sortOrder"} />
    <ImageField source={"image"} title={"name"} />
    <ReferenceField label="Lesson" source="lesson" reference="lessons">
      <ChipField source="name" />
    </ReferenceField>
  </ListGuesser>
);

export default SlideList;
