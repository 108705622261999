import { useEffect, useState } from 'react';

import { useDataProvider } from 'react-admin';

import './SlideChoicesTab.css';

import SlideChoicesEdit from './SlideChoicesEdit';
import SlideChoicesDetails from './SlideChoicesDetails';

const SlideChoicesTab = ({ record, history }) => {
  const [choices, setChoices] = useState([]);

  const dataProvider = useDataProvider();

  const createChoice = async (rect) => {
    const values = {
      leftPosition: parseInt(rect.x * 1000),
      topPosition: parseInt(rect.y * 1000),
      width: parseInt(rect.w * 1000),
      height: parseInt(rect.h * 1000),
      containerSlide: record.id,
    };

    const { data: choice } = await dataProvider.create('slide_choices', { data: values });

    const newChoices = [...choices];
    newChoices.push(choice);
    setChoices(newChoices);
  };

  const refreshChoice = (updatedChoice, index) => {
    const newChoices = [...choices];
    newChoices[index] = updatedChoice;
    setChoices(newChoices);
  };

  const onUpdateChoiceRect = async (rect, index) => {
    const values = {
      leftPosition: parseInt(rect.x * 1000),
      topPosition: parseInt(rect.y * 1000),
      width: parseInt(rect.w * 1000),
      height: parseInt(rect.h * 1000),
    };

    const { data: updatedChoice } = await dataProvider.update('slide_choices', {
      id: rect.id,
      data: values,
    });

    refreshChoice(updatedChoice, index);
  };

  const onUpdateChoice = async (choice, index) => {
    const { data: updatedChoice } = await dataProvider.update('slide_choices', {
      id: choice.id,
      data: choice,
    });

    refreshChoice(updatedChoice, index);
  };

  const onDeleteChoice = async (choice, index) => {
    await dataProvider.delete('slide_choices', { id: choice.id });

    const newChoices = [...choices];
    newChoices.splice(index, 1);
    setChoices(newChoices);

    history.go(0);
  };

  useEffect(() => {
    const getChoices = async () => {
      const promises = record.choices.map((choiceId) => dataProvider.getOne('slide_choices', { id: choiceId }));
      const result = await Promise.all(promises);

      setChoices(result.map((choiceData) => choiceData.data));
    };

    getChoices();
  }, [dataProvider, record.choices]);

  return (
    <div className="slide-choices-tab">
      <SlideChoicesEdit
        slide={record}
        choices={choices}
        onCreateRect={createChoice}
        onUpdateChoice={onUpdateChoiceRect}
      />
      <SlideChoicesDetails
        choices={choices}
        onUpdateChoice={onUpdateChoice}
        onDeleteChoice={onDeleteChoice}
        onRefreshChoice={(updatedChoice, index) => {
          refreshChoice(updatedChoice, index);
          history.go(0);
        }}
      />
    </div>
  );
};

export default SlideChoicesTab;
