
const mercureUrl = ((window._env_ && window._env_.MERCURE_SUBSCRIBE_URL)
  ? window._env_.MERCURE_SUBSCRIBE_URL
  : process.env.REACT_APP_MERCURE_HUB) ?? '';

export const subscribeToBulkUploadRequest = (iri, onMessage) => {
  const url = new URL(mercureUrl);
  url.searchParams.append('topic', iri);

  const eventSource = new EventSource(url.toString());
  eventSource.onmessage = onMessage;
};

export const subscribeToPendingRequests = async (dataProvider, onMessage) => {
  const { data: pendingRequests } = await dataProvider.getList('bulk_upload_requests', {
    filter: { 'exists[completedAt]': false },
    pagination: false,
    sort: false,
  });

  pendingRequests.forEach((bulkUploadRequest) => {
    subscribeToBulkUploadRequest(bulkUploadRequest.id, onMessage);
  });
};
