import { useEffect } from 'react';

import arrayMove from 'array-move';
import { Link } from 'react-router-dom';
import { linkToRecord } from 'ra-core';
import { Container, Draggable } from 'react-smooth-dnd';

import {
  Button as MuiButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Create as CreateIcon,
  DragHandle as DragHandleIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons';

import TreeIcon from '../images/TreeIcon';
import ListItemButton from '../shared/ListItemButton';

const LessonGridList = (props) => {
  const onDrop = async ({ removedIndex, addedIndex }) => {
    const newLessons = arrayMove(props.lessons, removedIndex, addedIndex)
    props.setLessons(newLessons);
  };

  useEffect(() => {
    if (props.ids && props.ids.length > 0 && props.data && props.lessons.length === 0) {
      props.setLessons(props.ids.map((id) => props.data[id]));
    }
  }, [props.ids, props.data, props]);

  if (!props.ids || !props.data) {
    return null;
  }

  return (
    <List>
      <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
        {props.lessons.map((lesson) => (
          <Draggable key={lesson.id}>
            <ListItem role={undefined} dense button>
              <ListItemIcon className="drag-handle">
                <DragHandleIcon />
              </ListItemIcon>
              <ListItemText primary={
                <>
                  {lesson.activity && (
                      <TreeIcon />
                  )}
                  {lesson.name}
                </>
              } />
              <ListItemSecondaryAction>
                <MuiButton
                  size="small"
                  variant="text"
                  color="primary"
                  component={Link}
                  edge="end"
                  aria-label="view"
                  to={linkToRecord('/lessons', lesson.id, 'show')}
                  onClick={(e) => e.stopPropagation()}
                  startIcon={<VisibilityIcon />}
                >
                  Show
                </MuiButton>
                <MuiButton
                  size="small"
                  variant="text"
                  color="primary"
                  component={Link}
                  edge="end"
                  aria-label="view"
                  to={linkToRecord('/lessons', lesson.id, 'edit')}
                  onClick={(e) => e.stopPropagation()}
                  startIcon={<CreateIcon />}
                >
                  Edit
                </MuiButton>
              </ListItemSecondaryAction>
            </ListItem>
          </Draggable>
        ))}
      </Container>
      <ListItemButton to={`/lessons/create?lesson=${props.setContent}&sortOrder=${props.lessons.length + 1}`} startIcon={<AddIcon />} label="Create" />
    </List>
  );
};

export default LessonGridList;
