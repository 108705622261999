import { useState } from 'react';
import { Redirect } from 'react-router-dom';

import {
  Edit as EditIcon,
  PlaylistAdd as PlaylistAddIcon,
} from '@material-ui/icons';
import {
  Chip,
  IconButton,
  TableCell,
} from '@material-ui/core';

const SetContentCell = ({
  block,
  dataProvider,
  set,
  setState,
  state,
  strand,
  theme,
}) => {
  const [navigateTo, setNavigateTo] = useState(null);

  const setContent = state.setContents.find(
    (candidateSetContent) => candidateSetContent.set === set.id
      && candidateSetContent.block === block.id,
  );

  const mouseOverBlock = (blockId) => {
    if (state.hoveredBlock !== blockId) {
      setState((state) => ({
        ...state,
        hoveredBlock: blockId,
      }));
    }
  }

  const mouseOutBlock = (blockId) => {
    if (state.hoveredBlock === blockId) {
      setState((state) => ({
        ...state,
        hoveredBlock: null,
      }));
    }
  }

  const createSetContent = async (strand, set, block) => {
    const newSetContentValues = {
      name: `${theme.name} ${strand.name} ${set.name} ${block.name}`,
      set: set.id,
      block: block.id,
      theme: theme.id,
    };

    const { data: setContent } = await dataProvider.create('set_contents', {data: newSetContentValues});
    const { setContents } = state;
    setContents.push(setContent);

    setState((state) => ({
      ...state,
      setContents,
    }));
  };

  const navigateToSetContent = (setContent) => {
    setNavigateTo(`/set_contents/${encodeURIComponent(setContent.id)}/show`);
  };

  const cellContent = () => {
    if (setContent === undefined) {
      return (
        <IconButton aria-label="delete" onClick={() => createSetContent(strand, set, block)}>
          <PlaylistAddIcon />
        </IconButton>
      );
    }

    const lessonCount = setContent.lessons.length;
    const lessonLabel = lessonCount === 1
      ? lessonCount + ' lesson'
      : lessonCount + ' lessons';

    return <Chip
      label={lessonLabel}
      deleteIcon={<EditIcon />}
      onClick={() => navigateToSetContent(setContent)}
      onDelete={() => navigateToSetContent(setContent)}
    />;
  };

  if (navigateTo !== null) {
    return <Redirect to={navigateTo} />;
  }

  return <TableCell
    align="center"
    className={`set-content ${state.hoveredBlock === block.id ? 'hovered' : ''}`}
    onMouseOver={() => mouseOverBlock(block.id)}
    onMouseOut={() => mouseOutBlock(block.id)}
  >
    {cellContent()}
  </TableCell>;
};

export default SetContentCell;
