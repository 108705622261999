import { useEffect } from 'react';

import { useDataProvider, useNotify } from 'react-admin';
import { subscribeToPendingRequests } from './shared/subscribeToBulkUploadRequest';

const UploadRequestSubscriber = ({ children }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  useEffect(() => {
    (async () => {
      await subscribeToPendingRequests(dataProvider, (evt) => {
        const data = JSON.parse(evt.data);

        if (data.theme) {
          notify(`The theme "${data.theme.name}" is uploaded correctly!`, 'success');
        }

        if (data.setContent) {
          notify(`The set content "${data.setContent.name}" is uploaded correctly!`, 'success');
        }
      });
    })();
  }, [dataProvider, notify]);

  return <>{children}</>;
};

export default UploadRequestSubscriber;