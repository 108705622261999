import {
  Edit,
  NumberInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import { fileToBase64 } from '../utils';

import SVGInput from '../shared/SVGInput';

const SlideEdit = (props) => (
  <Edit
    mutationMode="pessimistic"
    transform={async ({ image, ...rest}) => ({
      image: image ? (image.rawFile ? await fileToBase64(image.rawFile) : image.src) : null,
      ...rest
    })}

    {...props}
  >
    <SimpleForm redirect="show">
      <TextInput source="name" />
      <NumberInput source="sortOrder" initialValue={0} step={1} />
      <TextInput source="video" label="JWPlayer video code" />
      <SVGInput source="image" label="Slide Image" />
    </SimpleForm>
  </Edit>
);

export default SlideEdit;
