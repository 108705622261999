import { FieldGuesser, ShowGuesser } from "@api-platform/admin";

const BlockShow = props => (
  <ShowGuesser {...props}>
    <FieldGuesser source="name" addLabel={true} />
    <FieldGuesser source="sortOrder" addLabel={true} />
  </ShowGuesser>
);

export default BlockShow;
