import { useEffect } from 'react';

import arrayMove from 'array-move';
import { Container, Draggable } from 'react-smooth-dnd';
import { Link } from 'react-router-dom';
import { linkToRecord } from 'ra-core';

import {
  Button as MuiButton,
  GridList as MuiGridList,
  GridListTile,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemIcon,
  makeStyles,
  withWidth,
} from '@material-ui/core';
import {
  DragHandle as DragHandleIcon,
  Create as CreateIcon,
  Add as AddIcon,
  PictureInPicture as PictureInPictureIcon,
  SpeakerNotes as SpeakerNotesIcon,
} from '@material-ui/icons';

import { getColsForWidth, times } from '../utils';

import ListItemButton from '../shared/ListItemButton';

import './SlideGridList.css';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '-2px',

    '& li': {
      listStyle: 'none',
    },
  },
  gridList: {
    width: '100%',
    margin: 0,
  },
  placeholder: {
    backgroundColor: theme.palette.grey[300],
    height: '100%',
  },
}));

const LoadingGridList = ({ width, nbItems = 20 }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MuiGridList
        cellHeight={180}
        cols={getColsForWidth(width)}
        className={classes.gridList}
      >
        {' '}
        {times(nbItems, key => (
          <GridListTile key={key}>
            <div className={classes.placeholder} />
          </GridListTile>
        ))}
      </MuiGridList>
    </div>
  );
};

const LoadedGridList = ({
  ids,
  data,
  slides,
  setSlides,
  lessonId,
}) => {
  const classes = useStyles();

  const onDrop = async ({ removedIndex, addedIndex }) => {
    const newSlides = arrayMove(slides, removedIndex, addedIndex)
    setSlides(newSlides);
  };

  useEffect(() => {
    if (ids && ids.length > 0 && data && slides.length === 0) {
      const newSlides = []
      ids.sort((a,b) => data[a].sortOrder - data[b].sortOrder)
        .forEach((id, index) => {
          newSlides[index] = data[id];
        })
      setSlides(newSlides);
    }
  }, [data, ids, setSlides, slides, slides.length]);

  if (!slides || !data) return null;

  return (
    <div className={classes.root}>
      <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
        {slides.map((slide) => (
          <Draggable key={slide.id}>
            <ListItem role={undefined} dense button>
              <ListItemIcon className="drag-handle">
                <DragHandleIcon />
              </ListItemIcon>
              <ListItemText primary={`${slide.name} - ${slide.originId}`} />
              {(slide.choices.length > 0) && (
                <ListItemIcon>
                  <PictureInPictureIcon fontSize="large" />
                </ListItemIcon>
              )}
              {slide.notes && (
                <ListItemIcon>
                  <SpeakerNotesIcon fontSize="large" />
                </ListItemIcon>
              )}
              <img className='slide-img' src={slide.image} alt="" />
              <ListItemSecondaryAction>
                <MuiButton
                  size="small"
                  variant="text"
                  color="primary"
                  component={Link}
                  edge="end"
                  aria-label="view"
                  to={linkToRecord('/slides', slide.id, 'show')}
                  onClick={(e) => e.stopPropagation()}
                  startIcon={<CreateIcon />}
                >
                  Edit
                </MuiButton>
              </ListItemSecondaryAction>
            </ListItem>
          </Draggable>
        ))}
      </Container>

      <ListItemButton
        to={`/slides/create?lesson=${lessonId}&sortOrder=${slides.length}`}
        startIcon={<AddIcon />}
        label="Create"
      />
    </div>
  );
};

const SlideGridList = ({ loaded, ...props }) => loaded
  ? <LoadedGridList {...props} />
  : <LoadingGridList {...props} />;

export default withWidth()(SlideGridList);
