import { useEffect, useState } from 'react';

import {
  sanitizeListRestProps,
  useListContext,
  Confirm,
  CreateButton,
  List,
  Pagination,
  TopToolbar,
} from 'react-admin';
import { Link, useHistory } from 'react-router-dom';

import {
  GridListTile,
  GridListTileBar,
  Typography,
} from '@material-ui/core';

import GridList from '../shared/GridList';
import BulkUploadDialog from '../shared/BulkUploadDialog';

import './CategoriesList.css';

const CategoryListActions = (props) => {
  const {
    className,
    ...rest
  } = props;

  const [openConfirm, setOpenConfirm] = useState(false);
  const [confirmContent, setConfirmContent] = useState('');

  const history = useHistory();

  const { basePath } = useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <BulkUploadDialog
        title="Theme Bulk Upload"
        resource="themes"
        successMessage={(theme) => `Your request for upload the theme "${theme.name}" is placed correctly!`}
        onSuccess={(data) => {
          if (!data.theme) return;

          setConfirmContent(`The theme "${data.theme.name}" is uploaded correctly. Click "Confirm" to refresh.`);
          setOpenConfirm(true);
        }}
      />
      <CreateButton basePath={basePath} />

      <Confirm
        isOpen={openConfirm}
        title=""
        content={confirmContent}
        onConfirm={() => {
          setOpenConfirm(false);
          history.go(0);
        }}
        onClose={() => setOpenConfirm(false)}
      />
    </TopToolbar>
  );
};

const CategoryGridList = ({
  basePath,
  data,
  ids,
  loaded,
  width,
}) => {
  const [enabledCategoriesIds, setEnabledCategoriesIds] = useState([]);
  const [disabledCategoriesIds, setDisabledCategoriesIds] = useState([]);

  useEffect(() => {
    setEnabledCategoriesIds(ids.filter((id) => data[id].enabled));
    setDisabledCategoriesIds(ids.filter((id) => !data[id].enabled));
  }, [data, ids]);

  return (
    <div className="categories-list-container">
      <Typography variant="h6">Enabled Categories</Typography>
      <GridList
        basePath={basePath}
        data={data}
        ids={enabledCategoriesIds}
        loaded={loaded}
        width={width}
      />

      <Typography variant="h6">Disabled Categories</Typography>
      <GridList
        basePath={basePath}
        data={data}
        ids={disabledCategoriesIds}
        loaded={loaded}
        width={width}
      >
        <GridListTile
          component={Link}
          className="uncategorized-tile"
          to="/uncategorized"
        >
          <GridListTileBar
            title="Uncategorized"
          />
        </GridListTile>
      </GridList>
    </div>
  );
};

const CategoryList = (props) => (
  <List
    {...props}
    actions={<CategoryListActions />}
    perPage={20}
    pagination={<Pagination rowsPerPageOptions={[10, 20, 40]} />}
    sort={{ field: 'sortOrder', order: 'ASC' }}
  >
    <CategoryGridList />
  </List>
);

export default CategoryList;
