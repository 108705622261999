import {
  BooleanInput,
  Edit,
  NumberInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

const CategoryEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput label="Name" source="name" />
      <TextInput label="Image URL" source="image" />
      <BooleanInput label="Enabled" source="enabled" />
      <NumberInput label="Sort Order" source="sortOrder" />
    </SimpleForm>
  </Edit>
);

export default CategoryEdit;
