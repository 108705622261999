import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';

import SlideChoiceListItem from './SlideChoiceListItem';

const SlideChoicesDetails = ({ choices, onUpdateChoice, onDeleteChoice, onRefreshChoice }) => (
  <div className="slide-choices-details">
    <Paper>
      <Typography variant="h6">
        Hotspots
      </Typography>
      <List dense>
        {choices.map((choice, index) => (
          <SlideChoiceListItem
            key={choice.id}
            choice={choice}
            index={index}
            onUpdateChoice={onUpdateChoice}
            onRefreshChoice={onRefreshChoice}
            onDeleteChoice={onDeleteChoice}
          />
        ))}
      </List>
    </Paper>
  </div>
);

export default SlideChoicesDetails;
