import { useEffect, useState } from 'react';

import {
  DeleteButton,
  EditButton,
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  TopToolbar,
  ReferenceField,
  ChipField,
  useDataProvider,
} from 'react-admin'
import { linkToRecord } from 'ra-core';
import { makeStyles } from '@material-ui/core';

import SlideChoicesTab from './SlideChoicesTab';
import ChildSlidesTab from './ChildSlidesTab';
import SlideNotesTab from './SlideNotesTab';
import BreadcrumbNavigator from '../shared/BreadcrumbNavigator';
import generateCustomAdminPageStyles from '../shared/generateCustomAdminPageStyles';

const useStyles = makeStyles(generateCustomAdminPageStyles);

const SlideShowToolbar = ({ basePath, data, resource }) => {
  const [navigationPath, setNavigationPath] = useState([]);

  const classes = useStyles();

  const dataProvider = useDataProvider();

  useEffect(() => {
    if (!data) return;

    if (!data.lesson) {
      (async () => {
        const { data: parentSlide } = await dataProvider.getParentSlide({ id: data.originId });

        setNavigationPath([
          '...',
          { link: linkToRecord('#/slides', parentSlide['@id'], 'show'), label: parentSlide.name },
          data.name
        ]);
      })();

      return;
    }

    (async () => {
      const { data: lesson } = await dataProvider.getOne('lessons', { id: data.lesson });

      let label = lesson.name;
      if (lesson.activity) {
        const { data: name } = await dataProvider.getContainingLessonName({ id: lesson.originId });
        label = `${lesson.name} (${name['hydra:member'][0]})`;
      }

      setNavigationPath([
        '...',
        { link: linkToRecord('#/lessons', lesson['@id'], 'show'), label },
        data.name,
      ]);
    })();
  }, [data, dataProvider]);

  return (
    <TopToolbar className={classes.toolbar}>
      <BreadcrumbNavigator navigationPath={navigationPath} />
      <EditButton basePath={basePath} record={data} />
      {data && (
        <DeleteButton basePath={basePath} record={data} resource={resource} undoable={false} />
      )}
    </TopToolbar>
  );
};

const SlideShow = (props) => (
  <Show actions={<SlideShowToolbar />} {...props}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <TextField source={"name"} />
        <TextField source={"sortOrder"} />
        <ReferenceField label="Lesson" source="lesson" reference="lessons">
          <ChipField source="name" />
        </ReferenceField>
      </Tab>
      <Tab label="Hotspots">
        <SlideChoicesTab {...props} />
      </Tab>
      <Tab label="Child Slides">
        <ChildSlidesTab {...props} />
      </Tab>
      <Tab label="Notes">
        <SlideNotesTab {...props} />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default SlideShow;
