import { makeStyles, ListItem } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      textAlign: 'center',
      display: 'block',
      color: theme.palette.primary.main,
      "&.Mui-disabled": {
        color: theme.palette.action.disabled,
      },
    },
    labelText: {
      marginLeft: '8px',
      display: 'inline-block',
      verticalAlign: '.4em',
    },
  };
});

const ListItemButton = ({ to, startIcon, label, disabled = false}) => {
  const classes = useStyles();

  return (
    <ListItem button component={ Link } to={ to } color="primary" className={classes.root} disabled={disabled}>
      { startIcon }
      <span className={classes.labelText}>
        { label }
      </span>
    </ListItem>
  )
}

export default ListItemButton;
