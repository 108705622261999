import {
    Create,
    SimpleForm,
    TextInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    BooleanInput
} from 'react-admin';

const LessonCreate = (props) => {
    const params = new URLSearchParams(props.location.search);

    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source={"name"} />
                <NumberInput source={"sortOrder"} initialValue={Number(params.get('sortOrder')) ?? 0} step={1} />
                <BooleanInput source='activity' label="Activity" />
                <ReferenceInput label="Set Contents" source="setContent" reference="set_contents">
                    <SelectInput optionText="name" initialValue={params.get('lesson') ?? null} onChange={(value) => {
                        console.log (value);
                    }} />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};


export default LessonCreate;
