import SlideshowIcon from '@material-ui/icons/Slideshow';

import SlideList from './SlideList';
import SlideShow from './SlideShow';
import SlideCreate from './SlideCreate';
import SlideEdit from './SlideEdit';

export default {
  icon: SlideshowIcon,
  list: SlideList,
  show: SlideShow,
  create: SlideCreate,
  edit: SlideEdit,
};
