import ThemeIcon from '@material-ui/icons/Style';

import ThemeCreate from './ThemeCreate';
import ThemeEdit from './ThemeEdit';
import ThemeShow from './ThemeShow';

export default {
  create: ThemeCreate,
  edit: ThemeEdit,
  icon: ThemeIcon,
  show: ThemeShow,
};
