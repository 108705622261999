import { useEffect } from 'react';

import arrayMove from 'array-move';
import { Container, Draggable } from 'react-smooth-dnd';

import {
  Button as MuiButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  DragHandle as DragHandleIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
} from '@material-ui/icons';

import { useDataProvider } from 'react-admin';

const ThemeSortingList = ({
  ids,
  data,
  loading,
  themes,
  setThemes,
  onClose,
}) => {
  const dataProvider = useDataProvider();

  const onDrop = async ({ removedIndex, addedIndex }) => {
    const newThemes = arrayMove(themes, removedIndex, addedIndex)
    setThemes(newThemes);
  };

  const saveSortOrder = async () => {
    const categoryId = themes[0].category;
    await dataProvider.update('set_contents', {
      id: `${categoryId}/themes/set_order`,
      data: themes.map((theme) => theme.originId),
    });

    onClose();
  };

  useEffect(() => {
    if (!loading && ids && data && themes.length === 0) {
      setThemes(ids.map((id) => data[id]));
    }
  }, [data, ids, loading, setThemes, themes.length]);

  if (!ids || !data || loading) {
    return null;
  }

  return (
    <>
      <List>
        <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
          {themes.map((theme) => (
            <Draggable key={theme.id}>
              <ListItem role={undefined} dense button>
                <ListItemIcon className="drag-handle">
                  <DragHandleIcon />
                </ListItemIcon>
                <ListItemText primary={theme.name} />
                <img className='slide-img' src={theme.image} alt="" />
              </ListItem>
            </Draggable>
          ))}
        </Container>
      </List>

      <MuiButton
        variant="contained"
        color="primary"
        startIcon={<SaveIcon />}
        onClick={saveSortOrder}
      >
        Save sort order
      </MuiButton>

      <MuiButton
        variant="contained"
        startIcon={<CancelIcon />}
        onClick={onClose}
      >
        Cancel
      </MuiButton>
    </>
  );
};

export default ThemeSortingList;
