import { Button } from 'ra-ui-materialui';
import { Link } from 'react-router-dom';
import ContentAdd from '@material-ui/icons/Add';

const CreateThemeButton = ({
  categoryId = '',
  ...rest
}) => (
  <Button
    component={Link}
    to={{
      pathname: '/themes/create',
      state: { record: { category: categoryId }},
    }}
    label="Create Theme"
    {...rest}
  >
    <ContentAdd />
  </Button>
);

export default CreateThemeButton;
