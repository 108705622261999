import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { useDataProvider } from 'react-admin';
import { Button } from 'ra-ui-materialui';

import {
  makeStyles,
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import usePortal from '../hooks/usePortal';

import SetContentCell from './SetContentCell';

import './ThemeSetContents.css';

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 650,
  },
  formControl: {
    minWidth: 400,
  },
}));

const AddBlockDialog = ({
  open,
  onClose,
  blocks,
  onAddBlock
}) => {
  const [selectedIndex, setSelectedIndex] = useState('');

  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add Block</DialogTitle>
      <DialogContent>
        <FormControl className={classes.formControl}>
          <InputLabel id="block-select-label">Select a block</InputLabel>
          <Select
            labelId="block-select-label"
            value={selectedIndex}
            onChange={(evt) => setSelectedIndex(evt.target.value)}
          >
            {blocks.map((block, index) => (
              <MenuItem key={block.id} value={index}>{block.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <MuiButton onClick={onClose} color="primary">
          Cancel
        </MuiButton>
        <MuiButton onClick={() => onAddBlock(blocks[selectedIndex])} color="primary">
          Add
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
};

const ThemeSetContents = ({ record }) => {
  const [state, setState] = useState({
    shownBlocks: [],
    hiddenBlocks: [],
    setContents: [],
    sets: [],
    strands: [],
    hoveredBlock: null,
  });
  const [openDialog, setOpenDialog] = useState(false);

  const classes = useStyles();

  const dataProvider = useDataProvider();

  const addBlockTarget = usePortal('theme-add-block');

  const strandHeadClass = (strand) =>
    state.setContents.filter((setContent) => strand.sets.includes(setContent.set)).length > 0
    ? 'strand-head'
    : 'strand-head empty';

  const setHeadClass = (set) =>
    state.setContents.filter((setContent) => setContent.set === set.id).length > 0
      ? 'set-head'
      : 'set-head empty';

  const blockHeadClass = (block) => {
    const classNames = ['block-head'];
    if (state.setContents.filter((setContent) => setContent.block === block.id).length === 0) {
      classNames.push('empty');
    }

    if (state.hoveredBlock === block.id) {
      classNames.push('hovered');
    }

    return classNames.join(' ');
  };

  const onAddBlock = (block) => {
    setOpenDialog(false);

    const index = state.hiddenBlocks.indexOf(block);

    const newHiddenBlocks = [...state.hiddenBlocks];
    newHiddenBlocks.splice(index, 1);

    const newShownBlocks = [...state.shownBlocks];
    newShownBlocks.push(block);
    newShownBlocks.sort((a, b) => a.sortOrder - b.sortOrder);

    setState((state) => ({
      ...state,
      hiddenBlocks: newHiddenBlocks,
      shownBlocks: newShownBlocks,
    }));
  };

  // noinspection JSCheckFunctionSignatures
  const renderAddBlockButton = () => createPortal(
    <Button
      label="Add block"
      disabled={state.hiddenBlocks.length === 0}
      onClick={() => setOpenDialog(true)}
    >
      <AddIcon />
    </Button>,
    addBlockTarget
  );

  const renderStrandRow = (strand) => {
    const strandSets = state.sets.filter((set) => set.strand === strand.id);

    return (
      <TableBody key={`strand-rowSet-${strand.id}`}>
        {strandSets.map((set, index) => (
          <TableRow hover key={`set-row-${set.id}`}>
            {(index === 0) && (
              <TableCell
                rowSpan={strandSets.length}
                className={strandHeadClass(strand)}
              >
                {strand.name}
              </TableCell>
            )}

            <TableCell className={setHeadClass(set)}>{set.name}</TableCell>

            {state.shownBlocks.map((block) => (
              <SetContentCell
                key={`setContent-cell-${block.id}-${set.id}`}
                block={block}
                set={set}
                strand={strand}
                theme={record}
                state={state}
                setState={setState}
                dataProvider={dataProvider}
              />
            ))}
          </TableRow>
        ))}
      </TableBody>
    );
  };

  useEffect(() => {
    (async () => {
      const blocksPromise = await dataProvider.getList('blocks', {
        sort: { field: 'sortOrder', order: 'ASC' },
        pagination: false,
      });

      const setContentsPromise = dataProvider.getList('set_contents', {
        sort: { field: 'sortOrder', order: 'ASC' },
        filter: { theme: record.id },
        pagination: false
      });

      const [
        { data: blocks },
        { data: setContents },
      ] = await Promise.all([ blocksPromise, setContentsPromise ]);

      // We split the blocks in shown and hidden
      const shownBlocks = [];
      const hiddenBlocks = [];

      blocks.forEach((block) => {
        if (setContents.some((setContent) => setContent.block === block.id)) {
          shownBlocks.push(block);
        } else {
          hiddenBlocks.push(block);
        }
      });

      setState((state) => ({
        ...state,
        hiddenBlocks,
        setContents,
        shownBlocks,
      }));
    })();
  }, [dataProvider, record.id]);

  useEffect(() => {
    (async () => {

      const setsPromise = dataProvider.getList('sets', {
        sort: { field: 'sortOrder', order: 'ASC' },
        pagination: false,
        filter: false,
      });

      const strandsPromise = dataProvider.getList('strands', {
        sort: { field: 'sortOrder', order: 'ASC' },
        pagination: false,
        filter: false,
      });

      const [
        { data: strands },
        { data: sets }
      ] = await Promise.all([ strandsPromise, setsPromise ]);

      setState((state) => ({
        ...state,
        sets,
        strands,
      }));
    })();
  }, [dataProvider]);

  // noinspection JSCheckFunctionSignatures
  return (
    <>
      {renderAddBlockButton()}
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="set content table">
          <TableHead>
            <TableRow>
              <TableCell>Strand</TableCell>
              <TableCell>Set</TableCell>
              {state.shownBlocks.map((block) => (
                <TableCell align="center" className={blockHeadClass(block)} key={`block-head-${block.id}`}>
                  {block.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {state.strands.map(renderStrandRow)}
        </Table>
      </TableContainer>
      <AddBlockDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        blocks={state.hiddenBlocks}
        onAddBlock={onAddBlock}
      />
    </>
  );
};

export default ThemeSetContents;
