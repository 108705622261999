import { useState } from 'react';

import { useDataProvider, useNotify } from 'react-admin';

import ReactQuill from 'react-quill';

import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';

import 'react-quill/dist/quill.snow.css';
import './SlideNotesTab.css';

const quillModules = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline','strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image'],
    ['clean']
  ],
};

const quillFormats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'color',
];

const SlideNotesTab = ({ resource, record }) => {
  const [notes, setNotes] = useState(record.notes ?? '');

  const notify = useNotify();
  const dataProvider = useDataProvider();

  const saveNotes = async () => {
    try {
      await dataProvider.update(resource, {
        id: record.id,
        data: { notes },
      });

      notify('The notes has been saved successfully!');
    } catch (e) {
      notify('An error happens saving the notes!');
    }
  };

  return (
    <div className="slide-notes-tab">
      <h4>Edit the slide notes:</h4>
      <div className="editor-container">
        <ReactQuill
          theme="snow"
          modules={quillModules}
          formats={quillFormats}
          value={notes}
          onChange={(data) => {
            // Prevent store markup when notes are empty
            if (data.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
              setNotes('');
            } else {
              setNotes(data);
            }
          }}
        />
      </div>
      <Button
        variant="contained"
        color="primary"
        startIcon={<SaveIcon />}
        onClick={saveNotes}
      >
        Save notes
      </Button>
    </div>
  );
};

export default SlideNotesTab;
