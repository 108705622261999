
import {
  EditGuesser,
  InputGuesser,
} from '@api-platform/admin';

const ThemeEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser source="name" />
    <InputGuesser source="subtitle" />
    <InputGuesser source="sortOrder" />
    <InputGuesser source="image" />
    <InputGuesser source="category" />
  </EditGuesser>
);

export default ThemeEdit;
