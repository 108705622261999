import CategoryIcon from '@material-ui/icons/Category';

import CategoryList from './CategoryList';
import CategoryShow from './CategoryShow';
import CategoryEdit from './CategoryEdit';
import CategoryCreate from './CategoryCreate';

export default {
  icon: CategoryIcon,
  list: CategoryList,
  show: CategoryShow,
  edit: CategoryEdit,
  create: CategoryCreate,
};
