import { useEffect, useState } from 'react';

import {
  Link,
  linkToRecord,
  useDataProvider,
} from 'react-admin';

import LinearProgress from '@material-ui/core/LinearProgress';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import './ChildSlidesTab.css';

const ChildSlideTreeItem = ({ basePath, childSlide }) => (
  <TreeItem
    nodeId={childSlide.id}
    label={<Link to={linkToRecord(basePath, childSlide.id, 'show')}>{childSlide.name}</Link>}
  >
    {childSlide.children.map((child) => (
      <ChildSlideTreeItem
        key={child.id}
        childSlide={child}
        basePath={basePath}
      />
    ))}
  </TreeItem>
);

const ChildSlidesTab = ({ basePath, record }) => {
  const [loaded, setLoaded] = useState(false);
  const [nodeIds, setNodeIds] = useState([]);
  const [children, setChildren] = useState([]);

  const dataProvider = useDataProvider();

  useEffect(() => {
    let auxNodeIds = [];

    const fetchDestinationSlide = async (slideIRI) => {
      const { data: slide } = await dataProvider.getOne('slides', { id: slideIRI });

      return slide;
    };

    const fetchSlideChoice = async (choiceIRI) => {
      const { data: choice } = await dataProvider.getOne('slide_choices', { id: choiceIRI });

      return choice;
    };

    const fetchChildren = async (slide) => {
      let choices = await Promise.all(slide.choices.map((choice) => fetchSlideChoice(choice)));
      choices = choices.filter((choice) => !!choice.destinationSlide);

      slide.children = await Promise.all(choices.map((choice) => fetchDestinationSlide(choice.destinationSlide)));

      auxNodeIds = [...auxNodeIds, ...slide.children.map((slide) => slide.id)];

      await Promise.all(slide.children.map((child) => fetchChildren(child)));
    };

    const fetchTreeData = async () => {
      await fetchChildren(record);

      setChildren(record.children);
      setNodeIds(auxNodeIds);
      setLoaded(true);
    };

    fetchTreeData();

    return () => {
      setChildren([]);
      setNodeIds([]);
      setLoaded(false);
    };
  }, [dataProvider, record]);

  return (
    <div className="child-slides-tab">
      {!loaded && <LinearProgress className="child-slides-progress" />}
      {loaded && (
        <TreeView
          expanded={nodeIds}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          {children.map((child) => (
            <ChildSlideTreeItem
              key={child.id}
              childSlide={child}
              basePath={basePath}
            />
          ))}
        </TreeView>
      )}
    </div>
  );
};

export default ChildSlidesTab;
