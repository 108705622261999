import { useEffect, useState } from 'react';

import {
  BooleanField,
  Confirm,
  EditButton,
  NumberField,
  ReferenceArrayField,
  Show,
  SimpleShowLayout,
  TextField,
  useDataProvider,
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import { TopToolbar } from 'ra-ui-materialui';

import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { makeStyles } from '@material-ui/core/styles';

import GridList from '../shared/GridList';
import BulkUploadDialog from '../shared/BulkUploadDialog';
import CreateThemeButton from '../shared/CreateThemeButton';

import ThemeSortingList from './ThemeSortingList';

const useStyles = makeStyles(() => ({
  themes: {
    margin: '-2px',
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },

  deleteButton: {
    color: '#f44336',
    display: 'inline-flex',
    alignItems: 'center',
  },
}));

const CategoryShowActions = ({
  basePath,
  data,
  resource,
}) => {
  const [canDelete, setCanDelete] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [confirmContent, setConfirmContent] = useState('');

  const classes = useStyles();

  const dataProvider = useDataProvider();

  const history = useHistory();

  const deleteCategory = async () => {
    if (canDelete) {
      await dataProvider.delete(resource, data);
      await history.push(basePath);
    }

    setOpenDialog(false);
  };

  useEffect(() => {
    if (data) {
      setCanDelete(data.themes.length === 0);
    }
  }, [data]);

  return (
    <TopToolbar>
      <BulkUploadDialog
        title="Theme Bulk Upload"
        resource="themes"
        categoryId={data ? data.originId : ''}
        successMessage={(theme) => `Your request for upload the theme "${theme.name}" is placed correctly!`}
        onSuccess={(data) => {
          if (!data.theme) return;

          setConfirmContent(`The theme "${data.theme.name}" is uploaded correctly. Click "Confirm" to refresh.`);
          setOpenConfirm(true);
        }}
      />

      <EditButton basePath={basePath} record={data} />
      <CreateThemeButton categoryId={data ? data.id : null} />
      <MuiButton
        className={`ra-delete-button ${classes.deleteButton}`}
        startIcon={<DeleteIcon />}
        onClick={() => setOpenDialog(true)}
        size="small"
      >
        DELETE
      </MuiButton>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {!canDelete && (
              <>
                You are not able to delete a category with themes. Please remove all child themes before trying again.
              </>
            )}
            {canDelete && (
              <>
                Are you sure you want to delete this category? This action cannot be undone.
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {canDelete && (
            <MuiButton
              color="primary"
              onClick={() => setOpenDialog(false)}
            >
              CANCEL
            </MuiButton>
          )}
          <MuiButton
            color="primary"
            onClick={deleteCategory}
            autoFocus
          >
            ACCEPT
          </MuiButton>
        </DialogActions>
      </Dialog>

      <Confirm
        isOpen={openConfirm}
        title=""
        content={confirmContent}
        onConfirm={() => {
          setOpenConfirm(false);
          history.go(0);
        }}
        onClose={() => setOpenConfirm(false)}
      />
    </TopToolbar>
  );
};

const CategoryTitle = ({ record }) => {
  return <span>Category: {record ? record.name : ''}</span>;
};

const CategoryShow = (props) => {
  const [themes, setThemes] = useState([]);
  const [sortingThemes, setSortingThemes] = useState(false);

  const classes = useStyles();

  return (
    <Show title={<CategoryTitle />} actions={<CategoryShowActions />} {...props}>
      <SimpleShowLayout>
        <TextField label="Name" source="name" />
        <NumberField label="Sort" source="sortOrder" />
        <BooleanField label="Enabled" source="enabled" />

        {!sortingThemes && (
          <ReferenceArrayField
            className={classes.themes}
            label="Themes"
            reference="themes"
            source="themes"
            sort={{ field: 'sortOrder', order: 'ASC' }}
          >
            <GridList basePath='/themes' />
          </ReferenceArrayField>
        )}

        {!sortingThemes && (
          <div>
            <MuiButton
              variant="contained"
              color="primary"
              startIcon={<AutorenewIcon />}
              onClick={() => setSortingThemes(true)}
            >
              Sort themes
            </MuiButton>
          </div>
        )}

        {sortingThemes && (
          <ReferenceArrayField
            className={classes.themes}
            label="Themes"
            reference="themes"
            source="themes"
            sort={{ field: 'sortOrder', order: 'ASC' }}
          >
            <ThemeSortingList
              themes={themes}
              setThemes={setThemes}
              onClose={() => {
                setSortingThemes(false);
              }}
            />
          </ReferenceArrayField>
        )}
      </SimpleShowLayout>
    </Show>
  );
};

export default CategoryShow;
