import {
  createRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import Typography from '@material-ui/core/Typography';

const emptyCorners = {startX: 0, startY: 0, endX: 0, endY: 0};

const cornersToRect = (corners) => ({
  x: Math.min(corners.startX, corners.endX),
  y: Math.min(corners.startY, corners.endY),
  w: Math.abs(corners.startX - corners.endX),
  h: Math.abs(corners.startY - corners.endY),
});

const choiceToRect = (choice) => ({
  id: choice.id,
  x: choice.leftPosition / 1000.0,
  y: choice.topPosition / 1000.0,
  w: choice.width / 1000.0,
  h: choice.height / 1000.0,
  destination: (!!(choice.destinationSlide || choice.externalDestination))
});

const ChoiceRect = ({className, rect, onMouseDown, viewport, children}) => (
  <div
    className={`choice-rect ${className ?? ''}`}
    style={{
      width: `${rect.w * viewport.width}px`,
      height: `${rect.h * viewport.height}px`,
      left: `${rect.x * viewport.width}px`,
      top: `${rect.y * viewport.height}px`,
    }}
    onMouseDown={onMouseDown}
  >
    {children}
  </div>
);

const SlideChoicesEdit = ({ slide, choices, onCreateRect, onUpdateChoice }) => {
  const [draggingCorners, setDraggingCorners] = useState(null);
  const [rects, setRects] = useState([]);
  const [showPlaceholder, setShowPlaceholder] = useState(!slide.image);

  const viewport = useRef({ x: 0, y: 0, width: 0, height: 0 });
  const dragging = useRef(false);
  const picked = useRef({ rect: null, index: 0 });
  const startPoint = useRef({ x: 0, y: 0 });

  const choicesContainer = createRef();

  const getCoordsFromEvent = (evt) => ({
    x: (evt.pageX - viewport.current.x) / viewport.current.width,
    y: (evt.pageY - viewport.current.y) / viewport.current.height,
  });

  const initDraggingCorners = (evt) => {
    const corners = { ...emptyCorners };
    const coords = getCoordsFromEvent(evt);

    corners.startX = coords.x;
    corners.startY = coords.y;
    corners.endX = corners.startX;
    corners.endY = corners.startY;

    setDraggingCorners(corners);
  };

  const updateDraggingCorners = (evt) => {
    const corners = { ...draggingCorners };
    const coords = getCoordsFromEvent(evt);

    corners.endX = coords.x;
    corners.endY = coords.y;

    setDraggingCorners(corners);
  };

  const updatePicked = (evt) => {
    const coords = getCoordsFromEvent(evt);
    const { rect, index } = picked.current;

    const dx = coords.x - startPoint.current.x;
    const dy = coords.y - startPoint.current.y;

    const updatedRect = { ...rect };
    updatedRect.x += dx;
    updatedRect.y += dy;

    startPoint.current = coords;
    picked.current = { rect: updatedRect, index };

    const newRects = [...rects];
    newRects[index] = updatedRect;
    setRects(newRects);
  };

  const onMouseDown = (evt) => {
    dragging.current = true;

    initDraggingCorners(evt);
  };

  const onMouseMove = (evt) => {
    if (!dragging.current) {
      return;
    }

    if (picked.current.rect) {
      updatePicked(evt);

      return;
    }

    updateDraggingCorners(evt);
  };

  const onMouseUp = () => {
    if (!dragging.current) {
      return;
    }

    dragging.current = false;

    if (!picked.current.rect) {
      const rect = cornersToRect(draggingCorners);

      // Min rect is 1.5% x 1.5%
      if (rect.w > 0.015 && rect.h > 0.015) {
        onCreateRect(rect);
      }

      setDraggingCorners(null);
    }

    if (picked.current.rect) {
      const { rect, index } = picked.current;
      onUpdateChoice(rect, index);
    }

    picked.current = { rect: null, index: 0 };
    startPoint.current = { x: 0, y: 0 };
  };

  const onChoiceRectMouseDownFn = (rect, index) => ((evt) => {
    evt.stopPropagation();

    dragging.current = true;
    picked.current = { rect, index };
    startPoint.current = getCoordsFromEvent(evt);
  });

  useLayoutEffect(() => {
    viewport.current = choicesContainer.current.getBoundingClientRect();
  }, [choicesContainer]);

  useEffect(() => {
    setRects(choices.map((choice) => choiceToRect(choice)));
  }, [choices]);

  return (
    <div className="slide-choices-edit">
      <h4>Draw a rectangle to create a hotspot or drag to move the coordinates of the hotspot.</h4>
      <div className="slide-choices-wrapper">
        <div className="slide-image-container">
          {showPlaceholder && (
            <div className="slide-image-placeholder">
              <h3>You should upload first an image</h3>
            </div>
          )}

          {!showPlaceholder && (
              <img src={slide.image} alt={slide.name} onError={() => setShowPlaceholder(true)} />
          )}
        </div>

        <div className="slide-choices-workspace">
          <div
            ref={choicesContainer}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseMove={onMouseMove}
          >
            {draggingCorners && (
              <ChoiceRect
                className="dragging-rect"
                rect={cornersToRect(draggingCorners)}
                viewport={viewport.current}
              />
            )}

            {rects.map((rect, index) => (
              <ChoiceRect
                className={(rect.destination ? 'valid-destination' : '')}
                key={rect.id}
                rect={rect}
                onMouseDown={onChoiceRectMouseDownFn(rect, index)}
                viewport={viewport.current}
              >
                <Typography variant="body1">{`Hotspot #${index + 1}`}</Typography>
              </ChoiceRect>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideChoicesEdit;
