import { useMemo } from 'react';

import { useDropzone } from 'react-dropzone';

import { Typography } from '@material-ui/core';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const ZipDropzone = ({ zipFile, onDropFile }) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop: onDropFile,
    maxFiles: 1,
    multiple: false,
    accept: ['application/zip', 'application/octet-stream', '.zip'],
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  return (
    <div className="dropzone-container">
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <p>Drag and drop the ZIP file here, or click to select a file.</p>
      </div>
      {zipFile && (
        <Typography variant="body1" component="span">
          File: {zipFile.path} - {zipFile.size} bytes
        </Typography>
      )}
    </div>
  );
};

export default ZipDropzone;
