import { useEffect, useState } from 'react';

import {
  Button,
  ChipField,
  EditButton,
  ImageField,
  ReferenceField,
  Show,
  TextField,
  TopToolbar,
  useDataProvider,
} from 'react-admin';
import { Labeled } from 'ra-ui-materialui';

import GetAppIcon from '@material-ui/icons/GetApp';
import { Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ThemeSetContents from './ThemeSetContents';
import BreadcrumbNavigator from '../shared/BreadcrumbNavigator';
import {linkToRecord} from 'ra-core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    '& > *': {
      width: '100%',
      margin: '10px auto',
    },
  },

  card: {
    flex: '1 1 auto',
  },

  themeFields: {
    display: 'flex',
    flexFlow: 'row',
  },

  mainFields: {
    flexGrow: 2,
  },

  imageField: {
    width: '40%',
    '& img': {
      width: '100%',
      height: 'auto',
      maxHeight: 'unset',
      margin: 0,
    },
  },

  noImagePlaceholder: {
    width: '100%',
    backgroundColor: 'black',
    paddingTop: '56.25%', // 16:9
    position: 'relative',

    '& h3': {
      color: 'white',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },

  toolbar: {
    justifyContent: 'space-between',

    '& .theme-detail-actions': {
      display: 'flex',
    },
  },
}));

const ThemeTitle = ({ record }) => <span>Theme: {record ? record.name : ''}</span>;

const ThemeShowActions = ({ basePath, data }) => {
  const [navigationPath, setNavigationPath] = useState([]);

  const dataProvider = useDataProvider();
  const classes = useStyles();

  const exportZip = async () => {
    const { data: zipURL } = await dataProvider.exportTheme({ id: data.originId });
    window.open(zipURL, '_blank');
    URL.revokeObjectURL(zipURL);
  };

  useEffect(() => {
    if (!data) return;

    (async () => {
      const { data: category } = await dataProvider.getOne('categories', { id: data.category });

      setNavigationPath([
        {
          link: linkToRecord('#/categories', category.id, 'show'),
          label: category.name,
        },
        data.name
      ]);
    })();
  }, [data, dataProvider]);

  return (
    <TopToolbar className={classes.toolbar}>
      <BreadcrumbNavigator navigationPath={navigationPath} />

      <div className="theme-detail-actions">
        <div id="theme-add-block" />
        <Button onClick={exportZip} label="Export ZIP"><GetAppIcon /></Button>
        <EditButton basePath={basePath} record={data} />
      </div>
    </TopToolbar>
  );
};

const ThemeShowLayout = (props) => {
  const { record } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.themeFields}>

          <div className={classes.mainFields}>
            <Labeled label="Title" fullWidth {...props}>
              <TextField source="name" {...props} />
            </Labeled>
            <Labeled label="Subtitle" fullWidth {...props}>
              <TextField source="subtitle" {...props} />
            </Labeled>
            <Labeled label="Sort order" fullWidth {...props}>
              <TextField source="sortOrder" {...props} />
            </Labeled>
            <Labeled label="Category" fullWidth {...props}>
              <ReferenceField source="category" reference="categories"><ChipField source="name" /></ReferenceField>
            </Labeled>
          </div>

          <div className={classes.imageField}>
            <Labeled label="Theme Image" fullWidth {...props}>
              {record.image ? (
                <ImageField source="image" />
              ): (
                <div className={classes.noImagePlaceholder}>
                  <h3>Theme has no image</h3>
                </div>
              )}
            </Labeled>
          </div>
        </CardContent>
      </Card>

      <ThemeSetContents {...props} />
    </div>
  );
};

const ThemeShow = (props) => (
  <Show component="div" title={<ThemeTitle />} actions={<ThemeShowActions />} {...props}>
    <ThemeShowLayout />
  </Show>
);

export default ThemeShow;
