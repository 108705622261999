import { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';

import SlideChoiceDestinationModal from './SlideChoiceDestinationModal';

import './SlideChoiceListItem.css';

const SlideChoiceListItem = ({
  choice,
  index,
  onDeleteChoice,
  onUpdateChoice,
  onRefreshChoice,
}) => {
  const [values, setValues] = useState({ ...choice });
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const onUpdateFieldFn = (field) => ((evt) => {
    const update = { ...values };
    update[field] = evt.target.value;
    setValues(update);
  });

  const onBlurField = () => {
    const update = { ...values };
    update.width = parseInt(update.width * 10.0);
    update.height = parseInt(update.height * 10.0);
    update.leftPosition = parseInt(update.leftPosition * 10.0);
    update.topPosition = parseInt(update.topPosition * 10.0);

    onUpdateChoice(update, index);
  };

  useEffect(() => {
    const update = { ...choice };
    update.width = update.width / 10.0;
    update.height = update.height / 10.0;
    update.leftPosition = update.leftPosition / 10.0;
    update.topPosition = update.topPosition / 10.0;

    setValues(update);
  }, [choice]);

  return (
    <>
      <ListItem button onClick={() => setOpen(!open)}>
        <ListItemText primary={`Hotspot #${index + 1}`} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse className="choice-list-item-form" in={open} timeout="auto" unmountOnExit>
        <TextField
          label="Left position (%)"
          type="number"
          value={values.leftPosition}
          onChange={onUpdateFieldFn('leftPosition')}
          onBlur={onBlurField}
        />
        <TextField
          label="Top position (%)"
          type="number"
          value={values.topPosition}
          onChange={onUpdateFieldFn('topPosition')}
          onBlur={onBlurField}
        />
        <TextField
          label="Width position (%)"
          value={values.width}
          onChange={onUpdateFieldFn('width')}
          onBlur={onBlurField}
        />
        <TextField
          label="Height position (%)"
          value={values.height}
          onChange={onUpdateFieldFn('height')}
          onBlur={onBlurField}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<EditIcon />}
          onClick={() => setOpenModal(true)}
        >
          Edit destination
        </Button>
        <Button
          className="choice-list-item-delete"
          variant="contained"
          startIcon={<DeleteIcon />}
          onClick={() => onDeleteChoice(choice, index)}
        >
          DELETE
        </Button>
      </Collapse>

      <SlideChoiceDestinationModal
        choice={choice}
        open={openModal}
        onClose={() => setOpenModal(false)}
        onRefreshChoice={(updatedChoice) => onRefreshChoice(updatedChoice, index)}
      />
    </>
  );
};

export default SlideChoiceListItem;
