import LessonIcon from '@material-ui/icons/ViewWeek';

import LessonShow from './LessonShow';
import LessonCreate from './LessonCreate';
import LessonEdit from './LessonEdit';

export default {
  icon: LessonIcon,
  show: LessonShow,
  create: LessonCreate,
  edit: LessonEdit
};
