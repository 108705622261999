import {
  ImageField,
  Labeled,
  useInput,
} from 'react-admin';
import { useDropzone } from 'react-dropzone';

import IconButton from '@material-ui/core/IconButton';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: { width: '100%' },
    dropZone: {
      background: theme.palette.background.default,
      cursor: 'pointer',
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.getContrastText(
        theme.palette.background.default
      ),
    },
    preview: {
      display: 'inline-block',
    },
    removeButton: {
      display: 'inline-block',
      position: 'relative',
      float: 'left',
      '& button': {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        minWidth: theme.spacing(2),
        opacity: 0,
      },
      '&:hover button': {
        opacity: 1,
      },
    },
    removeIcon: {
      color: theme.palette.error.main,
    },
  })
);

const SVGInput = (props) => {
  const {
    label,
    options: {
      inputProps: inputPropsOptions,
    } = {},
    record,
    source,
    validate,
    value: defaultValue,
    ...rest
  } = props;

  const classes = useStyles(props);

  const transformInput = (input) => {
    if (!input) {
      return null;
    }

    if (typeof input === 'string') {
      return {
        src: input,
        title: record ? record.name : input,
      };
    }

    if (!(input instanceof File)) {
      return input;
    }

    return {
      rawFile: input,
      src: URL.createObjectURL(input),
      title: input.name,
    };
  };

  const {
    id,
    input: { onChange, value, ...inputProps },
  } = useInput({
    defaultValue,
    format: transformInput,
    parse: transformInput,
    source,
    type: 'file',
    validate,
    ...rest,
  });

  const onDrop = (newFiles) => {
    const updatedFiles = [...newFiles];
    onChange(updatedFiles[0]);
  };

  const onRemove = () => {
    onChange(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/svg+xml',
    multiple: false,
    onDrop,
  });

  return (
    <Labeled
      id={id}
      label={label}
      className={classes.root}
    >
      <>
        <div
          className={classes.dropZone}
          {...getRootProps()}
        >
          <input
            id={id}
            {...getInputProps({
              ...inputProps,
              ...inputPropsOptions,
            })}
          />
          <p>Drop a picture to upload, or click to select it.</p>
        </div>
        {value && (
          <div className="previews">
            <div className={classes.removeButton}>
              <IconButton
                onClick={onRemove}
                aria-label="Delete"
                title="Delete"
              >
                <RemoveCircle className={classes.removeIcon} />
              </IconButton>

              <ImageField record={value} source="src" className={classes.preview} />
            </div>
          </div>
        )}
      </>
    </Labeled>
  );
};

export default SVGInput;
