import { useSelector } from 'react-redux';
import { MenuItemLink } from 'react-admin';

import { useMediaQuery } from '@material-ui/core';

import blocks from '../blocks';
import strands from '../strands';
import themes from '../themes';

const Menu = ({ onMenuClick, dense, logout }) => {
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  return (
    <div>
      <MenuItemLink
        to={`/categories`}
        primaryText="Themes"
        leftIcon={<themes.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <MenuItemLink
        to={`/blocks`}
        primaryText="Blocks"
        leftIcon={<blocks.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <MenuItemLink
        to={`/strands`}
        primaryText="Strands"
        leftIcon={<strands.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />

      {isXSmall && logout}
    </div>
  );
};

export default Menu;
