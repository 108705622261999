import {
  Breadcrumbs,
  Link,
  Typography,
} from '@material-ui/core';
import { NavigateNext as NavigateNextIcon } from '@material-ui/icons';

const BreadcrumbNavigator = ({ navigationPath }) => (
  <Breadcrumbs
    className="breadcrumb-navigator"
    separator={<NavigateNextIcon fontSize="small" />}
    aria-label="breadcrumb"
  >
    {navigationPath.map((path) => (
      <Typography color="textPrimary" key={path}>
        {(typeof path) === 'string' ? path : (
          <Link href={path.link}>{path.label}</Link>
        )}
      </Typography>
    ))}
  </Breadcrumbs>
);

export default BreadcrumbNavigator;
