import { FieldGuesser, ShowGuesser } from "@api-platform/admin";
import {
    Datagrid,
    EditButton,
    ReferenceArrayField,
    RichTextField,
} from 'react-admin';

const StrandShow = props => (
  <ShowGuesser {...props}>
    <FieldGuesser source="name" addLabel={true} />
    <FieldGuesser source="sortOrder" addLabel={true} />
    <RichTextField source="description" />
    <ReferenceArrayField reference="sets" source="sets" label="Sets">
      <Datagrid>
        <FieldGuesser source="name" />
        <FieldGuesser source="sortOrder" />
        <RichTextField source="description" />
        <EditButton />
      </Datagrid>
    </ReferenceArrayField>
  </ShowGuesser>
);

export default StrandShow;
