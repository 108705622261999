import { useEffect, useState } from 'react';

import {
  Confirm,
  Labeled,
  ReferenceField,
  ReferenceManyField,
  Title,
  useNotify,
  useShowController,
} from 'react-admin';
import {
  makeStyles,
  Button as MuiButton,
  Card,
  CardContent,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {
  SaveAlt as SaveAltIcon,
  GetApp as GetAppIcon,
  Share as ShareIcon,
} from '@material-ui/icons';
import { useDataProvider } from 'react-admin';
import { useHistory } from 'react-router-dom';

import LessonGridList from '../lessons/LessonGridList';

import generateCustomAdminPageStyles from '../shared/generateCustomAdminPageStyles';
import BreadcrumbNavigator from '../shared/BreadcrumbNavigator';
import BulkUploadDialog from '../shared/BulkUploadDialog';

const useStyles = makeStyles(generateCustomAdminPageStyles);

const ReferenceNameField = ({ label, record }) => record
  ? (
    <Labeled label={label}>
      <Typography variant="body2">{ record.name }</Typography>
    </Labeled>
  )
  : null;

const SetContentShow = (props) => {
  const [lessons, setLessons] =  useState([]);
  const [navigationPath, setNavigationPath] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [confirmContent, setConfirmContent] = useState('');

  const { loaded, record } = useShowController(props);

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const history = useHistory();
  const notify = useNotify();

  const exportZip = async () => {
    const { data: zipURL } = await dataProvider.exportSetContent({ id: record.originId });
    window.open(zipURL, '_blank');
    URL.revokeObjectURL(zipURL);
  };

  const generateSetContentLink = async () => {
    const { data: setContent } = await dataProvider.getSetContentReel({ id: record.originId });

    const url = `${setContent.solsURL}/reel/public/${setContent.publicReel.id}`;
    await navigator.clipboard.writeText(url);

    notify('The URL is now on your clipboard!');
  };

  const saveSortOrder = async () => {
    await dataProvider.update('set_contents', {
      id: `${record.id}/lessons/set_order`,
      data: lessons.map(lesson => lesson.originId)
    });
  };

  useEffect(() => {
    if (!record) return;

    (async () => {
      const [
        { data: theme },
        { data: block },
        { data: set },
      ] = await Promise.all([
        dataProvider.getOne('themes', { id: record.theme }),
        dataProvider.getOne('blocks', { id: record.block }),
        dataProvider.getOne('sets', { id: record.set }),
      ]);

      const { data: strand } = await dataProvider.getOne('strands', { id: set.strand });

      setNavigationPath([
        '...',
        {
          link: `#/themes/${encodeURIComponent(theme.id)}/show`,
          label: theme.name,
        },
        `${strand.name} - ${set.name} - ${block.name}`,
      ]);
    })();
  }, [dataProvider, record]);

  return (
    <>
      <Title title={`Set Content: ${record ? record.name : '...'}`} />
      <Toolbar className={classes.toolbar}>
        <BreadcrumbNavigator navigationPath={navigationPath} />

        <MuiButton
            size="small"
            variant="text"
            color="primary"
            edge="end"
            aria-label="view"
            onClick={generateSetContentLink}
            startIcon={<ShareIcon />}
        >
          Shareable Link
        </MuiButton>

        {record && (
          <BulkUploadDialog
            title="Set Content Bulk Upload"
            resource="set_contents"
            setContentId={record.originId}
            successMessage={(setContent) =>
              `Your request for upload the set content "${setContent.name}" is placed correctly!`
            }
            onSuccess={(data) => {
              if (!data.setContent) return;

              setConfirmContent(`The set content "${data.setContent.name}" is uploaded correctly. Click "Confirm" to refresh.`);
              setOpenConfirm(true);
            }}
          />
        )}

        {/*<MuiButton
          size="small"
          variant="text"
          color="primary"
          edge="end"
          aria-label="view"
          onClick={exportZip}
          startIcon={<GetAppIcon />}
        >
          Export ZIP
        </MuiButton> */}
        <MuiButton
          size="small"
          variant="text"
          color="primary"
          edge="end"
          aria-label="view"
          onClick={saveSortOrder}
          startIcon={<SaveAltIcon />}
        >
          Save sort order
        </MuiButton>
      </Toolbar>
      <Card>
        <CardContent>
          <div className={classes.fieldContainer}>
            <div className={classes.field}>
              <ReferenceField
                resource={ props.resource }
                reference="sets"
                source="set"
                basePath={ props.basePath }
                record={record}
                link={false}
              >
                <ReferenceNameField label="Set"/>
              </ReferenceField>
            </div>
            <div className={classes.fieldSpacer} />
            <div className={classes.field}>
              <ReferenceField
                resource={ props.resource }
                reference="blocks"
                source="block"
                basePath={ props.basePath }
                record={record}
                link={false}
              >
                <ReferenceNameField label="Block" />
              </ReferenceField>
            </div>
            <div className={classes.fieldSpacer} />
            <div className={classes.field}>
              <ReferenceField
                resource={ props.resource }
                reference="themes"
                source="theme"
                basePath={ props.basePath }
                record={record}
                link={false}
              >
                <ReferenceNameField label="Theme" />
              </ReferenceField>
            </div>
            <div className={classes.fieldSpacer} />
          </div>

          {loaded && (
            <ReferenceManyField
              reference="lessons"
              target="lessons"
              label="Lessons"
              basePath={props.basePath}
              record={record}
              filter={{ setContent: record.id }}
              sort={{ field: 'sortOrder', order: 'ASC' }}
            >
              <LessonGridList record={record} loaded={loaded} lessons={lessons} setContent={record.id} setLessons={setLessons}/>
            </ReferenceManyField>
          )}
        </CardContent>
      </Card>

      <Confirm
        isOpen={openConfirm}
        title=""
        content={confirmContent}
        onConfirm={() => {
          setOpenConfirm(false);
          history.go(0);
        }}
        onClose={() => setOpenConfirm(false)}
      />
    </>
  )
};

export default SetContentShow;
