import {
  BooleanInput,
  Create,
  NumberInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

const CategoryCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <TextInput label="Name" source="name" />
      <TextInput label="Image URL" source="image" />
      <BooleanInput label="Enabled" source="enabled" />
      <NumberInput label="Sort Order" source="sortOrder" />
    </SimpleForm>
  </Create>
);

export default CategoryCreate;
