import { useRef, useEffect } from 'react';

/**
 * A hook for use a portal in react.
 *
 * @see https://www.jayfreestone.com/writing/react-portals-with-hooks/
 */
const usePortal = (id) => {
  const rootElemRef = useRef(null);

  const createRootElement = (id) => {
    const rootContainer = document.createElement('div');
    rootContainer.setAttribute('id', id);

    return rootContainer;
  };

  const addRootElement = (rootElem) => {
    document.body.insertBefore(
      rootElem,
      document.body.lastElementChild.nextElementSibling,
    );
  };

  useEffect(() => {
    const existingParent = document.querySelector(`#${id}`);
    const parentElem = existingParent || createRootElement(id);

    if (!existingParent) {
      addRootElement(parentElem);
    }

    const rootElem = rootElemRef.current;
    parentElem.prepend(rootElem);

    return () => {
      rootElem.remove();

      if (!parentElem.childElementCount) {
        parentElem.remove();
      }
    };
  }, [id]);

  const getRootElem = () => {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div');
    }

    return rootElemRef.current;
  };

  return getRootElem();
};

export default usePortal;
