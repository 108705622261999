import BlockIcon from '@material-ui/icons/ViewWeek';

import BlockList from './BlockList';
import BlockShow from './BlockShow';

export default {
  icon: BlockIcon,
  list: BlockList,
  show: BlockShow,
};
