import { useEffect, useState } from 'react';

import { Link, linkToRecord } from 'react-admin';

import {
  Button,
  FormControl,
  FormControlLabel,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

import { Form } from 'react-final-form';
import { useDataProvider } from 'react-admin';

import SVGInput from '../shared/SVGInput';
import { fileToBase64 } from '../utils';

import './SlideChoiceDestinationModal.css';

const SlideChoiceDestinationModal = ({ choice, open, onClose, onRefreshChoice }) => {
  const [destinationType, setDestinationType] = useState('slide');
  const [externalURL, setExternalURL] = useState('');
  const [destinationSlide, setDestinationSlide] = useState({
    name: '',
    video: '',
    image: '',
  });

  const dataProvider = useDataProvider();

  const createOrUpdateDestinationSlide = async () => {
    if (destinationSlide.video) {
      destinationSlide.image = null;
    }

    if (destinationSlide.image) {
      destinationSlide.video = null;
    }

    destinationSlide.containerChoice = choice.id;

    let result;
    if (destinationSlide.id) {
      result = await dataProvider.update('slides', {
        id: destinationSlide.id,
        data: destinationSlide,
      });
    } else {
      result = await dataProvider.create('slides', { data: destinationSlide });
    }

    return result.data;
  };

  const handleDestinationSlideSubmit = async () => {
    const slide = await createOrUpdateDestinationSlide();

    const values = { ...choice };
    values.destinationSlide = slide.id;
    delete values.externalDestination;

    const { data: updatedChoice } = await dataProvider.update('slide_choices', {
      id: choice.id,
      data: values,
    });

    return updatedChoice;
  };
  
  const handleExternalDestinationSubmit = async () => {
    const values = {...choice};
    values.destinationSlide = null;
    values.externalDestination = externalURL;

    const {data: updatedChoice} = await dataProvider.update('slide_choices', {
      id: choice.id,
      data: values,
    });

    if (choice.destinationSlide) {
      await dataProvider.delete('slides', {id: choice.destinationSlide});
    }

    return updatedChoice;
  };

  const isSlideDestinationInvalid = () =>
    destinationType === 'slide' && (!!destinationSlide.image && !!destinationSlide.video);

  const handleSubmit = async () => {
    let updatedChoice;
    if (destinationType === 'slide') {
      updatedChoice = await handleDestinationSlideSubmit();
    } else if (destinationType === 'link') {
      updatedChoice = await handleExternalDestinationSubmit();
    }
    onRefreshChoice(updatedChoice);

    onClose();
  };

  const renderForm = ({ handleSubmit }) => (
    <form onSubmit={handleSubmit}>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="destinationType"
          name="destinationType"
          value={destinationType}
          onChange={(evt) => setDestinationType(evt.target.value)}
        >
          {/* Slide */}
          <div className="choice-destination-group-header">
            <FormControlLabel
              value="slide"
              control={<Radio />}
              label="Slide Destination"
            />

            <Button
              variant="contained"
              color="primary"
              disabled={destinationType !== 'slide' || !destinationSlide.id}
            >
              <Link className="choice-destination-link" to={linkToRecord('/slides', destinationSlide.id, 'show')}>
                View Slide
              </Link>
            </Button>
          </div>

          <TextField
            label="Name"
            variant="filled"
            disabled={destinationType !== 'slide'}
            value={destinationSlide.name}
            onChange={(evt) => {
              setDestinationSlide({
                ...destinationSlide,
                name: evt.target.value,
              });
            }}
          />

          <TextField
            label="JWPlayer video code"
            variant="filled"
            error={isSlideDestinationInvalid()}
            disabled={destinationType !== 'slide'}
            value={destinationSlide.video}
            onChange={(evt) => {
              setDestinationSlide({
                ...destinationSlide,
                video: evt.target.value,
              });
            }}
          />

          <SVGInput
            source="image"
            label="Slide Image"
            disabled={destinationType !== 'slide'}
            value={destinationSlide.image}
            onChange={async (value) => {
              setDestinationSlide({
                ...destinationSlide,
                image: (value instanceof File) ? await fileToBase64(value) : value,
              });
            }}
          />

          {/* URL */}
          <div className="choice-destination-group-header">
            <FormControlLabel
              value="link"
              control={<Radio />}
              label="External Destination"
            />
          </div>

          <TextField
            label="URL"
            variant="filled"
            disabled={destinationType !== 'link'}
            value={externalURL}
            onChange={(evt) => setExternalURL(evt.target.value)}
          />
        </RadioGroup>
      </FormControl>

      <Button
        variant="contained"
        color="primary"
        type="submit"
        startIcon={<SaveIcon />}
        disabled={isSlideDestinationInvalid()}
      >
        Save
      </Button>
    </form>
  );

  useEffect(() => {
    if (choice.destinationSlide) {
      (async (id) => {
        const { data: slide } = await dataProvider.getOne('slides', { id: id })
        setDestinationSlide(slide);
      })(choice.destinationSlide);

      setDestinationType('slide');
    }

    if (choice.externalDestination) {
      setExternalURL(choice.externalDestination);
      setDestinationType('link');
    }
  }, [choice, dataProvider]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="choice-destination-modal-title"
    >
      <div className="choice-destination-modal">
        <Paper>
          <Typography id="choice-destination-modal-title" variant="h5">Choice destination</Typography>
          <Form onSubmit={handleSubmit} render={renderForm} />
        </Paper>
      </div>
    </Modal>
  );
};

export default SlideChoiceDestinationModal;
