import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
} from 'react-admin';

import { fileToBase64 } from '../utils';

import SVGInput from '../shared/SVGInput';

const SlideCreate = (props) => {
  const params = new URLSearchParams(props.location.search);

  const transform = async ({ image, ...rest}) => ({
    image: image ? (image.rawFile ? await fileToBase64(image.rawFile) : image.src) : null,
    ...rest
  });

  return (
      <Create {...props} transform={transform}>
        <SimpleForm redirect="show">
          <TextInput source="name" />
          <TextInput source="lesson" initialValue={params.get('lesson') ?? null} disabled />
          <NumberInput source="sortOrder" initialValue={Number(params.get('sortOrder')) ?? 0} step={1} />
          <TextInput source="video" label="JWPlayer video code" />
          <SVGInput source="image" label="Slide Image" />
        </SimpleForm>
      </Create>
  );
};

export default SlideCreate;
