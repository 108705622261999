import { FieldGuesser, ListGuesser } from "@api-platform/admin";
import {
    ChipField,
    ReferenceArrayField,
    RichTextField,
    SingleFieldList,
} from 'react-admin';

const StrandList = (props) => (
  <ListGuesser {...props}>
    <FieldGuesser source="name" />
    <FieldGuesser source="sortOrder" />
    <RichTextField source="description" />
    <ReferenceArrayField reference="sets" source="sets" label="Sets">
      <SingleFieldList>
        <ChipField source="name" />
      </SingleFieldList>
    </ReferenceArrayField>
  </ListGuesser>
);

export default StrandList;
