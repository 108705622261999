import { EditGuesser, InputGuesser } from '@api-platform/admin';
import RichTextInput from 'ra-input-rich-text';
import { ReferenceArrayInput, SelectArrayInput } from 'react-admin';

const StrandEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser source="name" />
    <InputGuesser source="sortOrder" />
    <RichTextInput source="description" />
    <ReferenceArrayInput source="sets" reference="sets">
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
  </EditGuesser>
);

export default StrandEdit;
