
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin';

const ThemeCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" />
      <NumberInput source="sortOrder" initialValue={0} step={1} />
      <TextInput source="image" label="Image URL" />
      <TextInput source="subtitle" />
      <ReferenceInput label="Category" source="category" reference="categories" allowEmpty>
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default ThemeCreate;
