import { FieldGuesser, ListGuesser } from "@api-platform/admin";

const BlockList = (props) => (
  <ListGuesser {...props}>
    <FieldGuesser source="name" />
    <FieldGuesser source="sortOrder" />
  </ListGuesser>
);

export default BlockList;
