
import { Redirect, Route } from 'react-router-dom';
import {
  fetchHydra as baseFetchHydra,
  hydraDataProvider as baseHydraDataProvider
} from '@api-platform/admin';
import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';

const fetchHeaders = () => ({
  Authorization: `Bearer ${localStorage.getItem('token')}`
});

const fetchHydra = (url, options = {}) => baseFetchHydra(new URL(url), {
  ...options,
  headers: new Headers(fetchHeaders()),
});

const apiDocumentationParser = (entrypoint) => parseHydraDocumentation(entrypoint).then(
  ({ api }) => ({ api }),
  (result) => {
    switch (result.status) {
      case 401:
        localStorage.removeItem('token');

        return Promise.resolve({
          api: result.api,
          customRoutes: [
            <Route
              path="/"
              render={() => {
                if (localStorage.getItem('token')) {
                  return window.location.reload();
                } else {
                  return <Redirect to="/login" />;
                }
              }}
            />,
          ],
        });

      default:
        return Promise.reject(result);
    }
  }
);

export default (entrypoint) => {
  const baseDataProvider = baseHydraDataProvider(
    entrypoint,
    fetchHydra,
    apiDocumentationParser
  );

  const bulkUpload = (url, params) => {
    const formData = new FormData();
    formData.append('file', params.file);
    if (params.categoryId) {
      formData.append('category', params.categoryId);
    }

    return fetchHydra(url, {
      method: 'POST',
      body: formData,
    }).then(
      ({json}) => ({data: json}),
      (error) => {
        if (error.response) {
          return error.response.json().then((error) => Promise.reject({data: error['hydra:description'] }));
        }

        return Promise.reject({ data: error.message});
      }
    );
  };

  return {
    ...baseDataProvider,
    create: (resource, params) => {
      if (resource === 'themes' && params.file) {
        return bulkUpload(`${entrypoint}/${resource}/bulk_upload`, params);
      }

      if (resource === 'set_contents' && params.file) {
        return bulkUpload(`${entrypoint}/${resource}/${params.id}/bulk_upload`, params);
      }

      // fallback to the default implementation
      return baseDataProvider.create(resource, params);
    },

    getParentSlide: (params) => {
      return fetchHydra(`${entrypoint}/slides/${params.id}/parent_slide`, {
        method: 'GET',
      }).then(
        ({json}) => ({data: json}),
        ({response}) => response.json().then((error) => Promise.reject(error)),
      );
    },

    getContainingLessonName: (params) => {
      return fetchHydra(`${entrypoint}/lessons/${params.id}/containing_lesson_name`, {
        method: 'GET',
      }).then(
        ({json}) => ({data: json}),
        ({response}) => response.json().then((error) => Promise.reject(error)),
      );
    },

    exportTheme: (params) => {
      return fetch(`${entrypoint}/themes/${params.id}/zip_export`, {
        method: 'GET',
      }).then(
        (response) => (response.blob()),
        ({ response }) => response.json().then((error) => Promise.reject(error)),
      ).then((blob) => ({ data: window.URL.createObjectURL(blob) }))
    },

    getSetContentReel: (params) => {
      return fetchHydra(`${entrypoint}/set_contents/${params.id}/public_reel`, {
        method: 'GET',
      }).then(
          ({json}) => ({ data: json }),
          ({response}) => response.json().then((error) => Promise.reject(error)),
      );
    },
    getLessonPublicReel: (params) => {
      return fetchHydra(`${entrypoint}/lessons/${params.id}/public_reel`, {
        method: 'GET',
      }).then(
          ({json}) => ({ data: json }),
          ({response}) => response.json().then((error) => Promise.reject(error)),
      );
    }
  };
};
