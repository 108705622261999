const generateCustomAdminPageStyles = (theme) => ({
  fieldContainer: {
    display: 'flex',
    width: '100%',
  },
  fieldSpacer: {
    flex: '1 1',
  },
  toolbar: {
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    paddingRight: 0,
    paddingTop: '24px',
    paddingBottom: '8px',
    minHeight: '40px',
    [theme.breakpoints.up('xs')]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
    },

    '& .breadcrumb-navigator': {
      flex: 1,
    },
  },
  actions: {
    paddingTop: theme.spacing(3),
    minHeight: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
    },
  },
});

export default generateCustomAdminPageStyles;
