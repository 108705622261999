import { useEffect, useState } from 'react';

import { Title, useDataProvider } from 'react-admin';

import {
  Card,
  CardContent,
  Toolbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import GridList from '../shared/GridList';
import generateCustomAdminPageStyles from '../shared/generateCustomAdminPageStyles';
import CreateThemeButton from '../shared/CreateThemeButton';

const useStyles = makeStyles(generateCustomAdminPageStyles);

const UncategorizedThemes = () => {
  const [loaded, setLoaded] = useState(false);
  const [themes, setThemes] = useState({});

  const classes = useStyles();
  const dataProvider = useDataProvider();

  useEffect(() => {
    (async () => {
      const response = await dataProvider.getList('themes', {
        sort: false,
        pagination: false,
        filter: { 'exists[category]': false },
      });

      const data = {};
      response.data.forEach((theme) => data[theme.id] = theme);

      setThemes(data);
      setLoaded(true);
    })();
  }, [dataProvider]);

  return (
    <>
      <Title title="Uncategorized Themes" />
      <Toolbar className={classes.toolbar}>
        <CreateThemeButton />
      </Toolbar>
      <Card className={classes.root}>
        <CardContent>
          <GridList
            basePath="/themes"
            data={themes}
            ids={Object.keys(themes)}
            loaded={loaded}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default UncategorizedThemes;