import { linkToRecord } from 'ra-core';
import { Link } from 'react-router-dom';

import {
  GridList as MuiGridList,
  GridListTile,
  GridListTileBar,
  withWidth,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { getColsForWidth, times } from '../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '-2px',
    padding: '10px',
  },

  gridList: {
    width: '100%',
    margin: 0,
  },

  tileBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)',
  },

  placeholder: {
    backgroundColor: theme.palette.grey[300],
    height: '100%',
  },
}));

const LoadingGridList = ({
  nbItems = 20,
  width,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MuiGridList
        cellHeight={180}
        cols={getColsForWidth(width)}
        className={classes.gridList}
      >
        {' '}
        {times(nbItems, key => (
          <GridListTile key={key}>
            <div className={classes.placeholder} />
          </GridListTile>
        ))}
      </MuiGridList>
    </div>
  );
};

const LoadedGridList = ({
  basePath,
  children,
  data,
  ids,
  width,
}) => {
  const classes = useStyles();

  if (!ids || !data) return null;

  return (
    <div className={classes.root}>
      <MuiGridList
        cellHeight={180}
        cols={getColsForWidth(width)}
        className={classes.gridList}
      >
        {ids.map((id) => (
          <GridListTile
            component={Link}
            key={id}
            to={`${linkToRecord(basePath, data[id].id)}/show`}
          >
            <img src={data[id].image} alt="" />
            <GridListTileBar
              className={classes.tileBar}
              title={data[id].name}
              subtitle={data[id].subtitle}
            />
          </GridListTile>
        ))}
        {children}
      </MuiGridList>
    </div>
  );
};

const GridList = ({ loaded, ...props }) =>
  loaded ? <LoadedGridList {...props} /> : <LoadingGridList {...props} />;

export default withWidth()(GridList);
