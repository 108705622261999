import { Layout, Sidebar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles'

import AppBar from './AppBar';
import Menu from './Menu';

import UploadRequestSubscriber from '../UploadRequestSubscriber';

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    [theme.breakpoints.up('md')]: {
      border: 'none',
      marginTop: '1.5em',
    },
  },
}));

const CustomSidebar = (props) => (
  <Sidebar
    classes={useStyles()}
    {...props}
    size={200}
  />
);

const theme = {
  palette: {
    primary: {
      main: '#39b54a',
    },
    secondary: {
      light: '#5f5fc4',
      main: '#283593',
      dark: '#001064',
      contrastText: '#ff0',
    },
    background: {
      default: '#f0f4f7',
    },
  },
  shape: {
    borderRadius: 10,
  },
  overrides: {
    RaMenuItemLink: {
      root: {
        borderLeft: '3px solid #fff',
      },
      active: {
        borderLeft: '3px solid #39b54a',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
      root: {
        border: '1px solid #e0e0e3',
        backgroundClip: 'padding-box',
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: '#fff',
        color: '#39b54a',
        boxShadow: 'none',
      },
    },
    MuiAppBar: {
      colorSecondary: {
        color: '#fff',
        backgroundColor: '#146ab0',
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: '#f5f5f5',
      },
      barColorPrimary: {
        backgroundColor: '#d7d7d7',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        '&$disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      },
    },
  },
};

const AdminLayout = (props) => (
  <UploadRequestSubscriber>
    <Layout
      {...props}
      appBar={AppBar}
      sidebar={CustomSidebar}
      menu={Menu}
      theme={theme}
    />
  </UploadRequestSubscriber>
);

export default AdminLayout;
